<template>


<div class="px-4 py-0 md:px-6 lg:px-0 mb-3">
    <div class="grid">

        <div class="col-12 md:col-6 lg:col-2" v-if='report.breakfastTotal > 0 && activeProjectDefault.isBreakfastVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Sabah Kahvaltısı</span>
                        <div class='text-900 font-bold text-xl'>{{ report.breakfastTotal}}</div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/kahvalti.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 md:col-6 lg:col-2" v-if='report.lunchTotal > 0 && activeProjectDefault.isLunchVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Öğlen Yemeği</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ report.lunchTotal }}
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/oglen_yemegi.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2" v-if='report.dinnerTotal > 0 && activeProjectDefault.isDinnerVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Akşam Yemeği</span>
                        <div class='text-900 font-bold text-xl'>

                            {{ report.dinnerTotal }}

                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/aksam_yemegi.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2" v-if='report.nightMealTotal > 0 && activeProjectDefault.isNightMealVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Gece Yemeği</span>
                        <div class='text-900 font-bold text-xl'>

                            {{ report.nightMealTotal }}

                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/gece.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2" v-if='report.snackTotal > 0 && activeProjectDefault.isSnackVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Kumanya</span>
                        <div class='text-900 font-bold text-xl'>
                            {{ report.snackTotal }}
                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/kumanya.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2" v-if='report.iftarTotal > 0 && activeProjectDefault.isIftarVisible '>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>İftar</span>
                        <div class='text-900 font-bold text-xl'>

                            {{ report.iftarTotal }}

                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/iftar.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2" v-if='report.sahurTotal >0 && activeProjectDefault.isSahurVisible'>
            <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class='block font-medium mb-3 text-xl'>Sahur</span>
                        <div class='text-900 font-bold text-xl'>

                            {{ report.sahurTotal }}

                        </div>
                    </div>
                    <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                        <i>
                            <img src='/layout/images/icon/sahur.svg' height='54' class='mt-2 mr-2' />
                        </i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-12'>

           <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                <div class='col-12 lg:col-9'>
                <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Sipariş Listesi</h5>
                </div>

                <div class='col-12 lg:col-3 text-right'>

                    <span>

                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2' />

                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                        <h3>Firma Sipariş Listesi</h3>

                        <p class="text-lg">
                        Bu sayfada yer alan veriler taşeron firmanın taahhüt ettiği sipariş sayılarını öğünler bazında varsayılan karşılığıdır.<br>
                        <br>
                        Bu sayfada gireceğiniz veriler günlük giriş yapılacak hakediş sayfasına varsayılan olarak yansır. Firma taahhüt ettiği miktarı değiştirdiğinde sonraki günün hakediş listesine yansır.<br>
                        <br>
                        Üst tarafta yer alan öğün toplam sayıları hazırlık yapan personelinize SMS olarak gönderilecektir. Bu veriye göre + veya - öğün içeriğini hazırlayabileceklerdir.<br>
                        <br>
                        GSM ve E-Posta izni kullanıcı talep ettiğinde taahhüt sayılarının günlük olarak tarafına ulaşmasını sağlayan bir sistemdir. Giden mesajda yer alan linke tıklayarak versayılan siparişini düşürebilir veya artırabilir.<br>                     
                        <br>
                        </p>
                        </Sidebar>

                    </span>


                <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>
                </div>

                 

                </div>

                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='6'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['name']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle'
                                    class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Taşeron firma listesi yükleniyor. Lütfen bekleyin.
                    </template>


                    <Column field='name' header='Firma İsmi' filterField='name' :showFilterMatchModes='false' :sortable='true'>
                    </Column>


                    <Column field='breakfastQuantity' header='Sabah Kahvaltısı' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible'>
                    </Column>
                    <Column field='lunchQuantity' header='Öğlen Yemeği' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible'>
                    </Column>
                    <Column field='dinnerQuantity' header='Akşam Yemeği' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isDinnerVisible'>
                    </Column>
                    <Column field='nightMealQuantity' header='Gece Yemeği' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible'>
                    </Column>
                    <Column field='snackQuantity' header='Kumanya' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible'>
                    </Column>
                    <Column field='iftarQuantity' header='İftar' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible'>
                    </Column>
                    <Column field='sahurQuantity' header='Sahur' :sortable='true' style='width:4rem' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible'>
                    </Column>



                    <Column field='verified' header='SMS Durum' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.allowGsm, 'text-pink-500 pi-times-circle': !data.allowGsm}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='E-Posta Durum' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.allowEmail, 'text-pink-500 pi-times-circle': !data.allowEmail}"></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='edit(slotProps.data)'/>
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import FirmService from '../../services/firmService';
import { getActiveProjectDefaults, getActiveProjectId } from '../common/commonConstantFunctions';

export default {
    data() {
        return {
            dataList: [],
            report: {
                breakfastTotal : 0,
                lunchTotal : 0,
                dinnerTotal :0,
                snackTotal :0,
                iftarTotal:0,
                sahurTotal:0,
                nightMealTotal:0
            },
            activeProjectDefault: {},
            visibleLeft: false,
            projectId:0,
            filters: null,
            loadingIndicator: true,
        };
    },
    _firmService: null,
    created() {
        this._firmService = new FirmService();
        this.initFilters();
        this.activeProjectDefault = getActiveProjectDefaults();
        this.projectId = getActiveProjectId();
    },
    async mounted() {
        this.loadingIndicator = true;
        const response = await this._firmService.getAllFirmsWithProjectIdForDefaults(this.projectId);
        this.dataList = response.data;
        await this.getTotalReport(this.projectId);
        this.loadingIndicator = false;
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },

        edit(item) {
            this.$router.push({ name: 'defaultQuantitiesFirm', params: { firmId: item.id } });
        },
        async getTotalReport(projectId) {
            const reportResponse = await this._firmService.getFirmDefaultReports(projectId);
            if (reportResponse.isSuccess) {
                this.report.breakfastTotal = reportResponse.data.breakfastTotal;
                this.report.lunchTotal = reportResponse.data.lunchTotal;
                this.report.dinnerTotal = reportResponse.data.dinnerTotal;
                this.report.snackTotal = reportResponse.data.snackTotal;
                this.report.iftarTotal = reportResponse.data.iftarTotal;
                this.report.sahurTotal = reportResponse.data.sahurTotal;
                this.report.nightMealTotal = reportResponse.data.nightMealTotal;
            }
        }
        ,
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._firmService.getAllFirmDefaultsWithProjectIdExcel(this.projectId);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Proje Taşeronları -  " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
    },
};
</script>

